'use client'

import { buttonBaseClasses } from '@mui/material/ButtonBase'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import { alpha, styled } from '@mui/material/styles'

import { CIQIconButtonProps } from 'components/atoms/CIQIconButton/CIQIconButton.types'

const CIQIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'variant' && prop !== 'isActive',
})<CIQIconButtonProps>(({ theme, color = 'primary', variant = 'standard', isActive = false }) => ({
  [`&.${iconButtonClasses.root}`]: {
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),

    ...(variant === 'standard' && {
      background: 'none',
      color: theme.palette[color].main,

      '&.Mui-disabled ': {
        opacity: 0.5,
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, theme.palette.action.hoverOpacity),
      },

      [`&.${buttonBaseClasses.focusVisible}`]: {
        backgroundColor: alpha(theme.palette.common.black, theme.palette.action.focusOpacity),
      },

      [`&.${iconButtonClasses.disabled}`]: {
        background: 'none',
        color: theme.palette.grey.A400,
      },

      ...(isActive && {
        backgroundColor: alpha(theme.palette.common.black, theme.palette.action.selectedOpacity),

        '&:hover': {
          backgroundColor: alpha(
            theme.palette.common.black,
            theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
        },
      }),
    }),

    ...(variant === 'filled' && {
      backgroundColor: theme.palette[color].main,
      color: theme.palette[color].contrastText,

      '&:hover': {
        backgroundColor: theme.palette[color].dark,
      },

      [`&.${buttonBaseClasses.focusVisible}`]: {
        backgroundColor: theme.palette[color].dark,
      },

      [`&.${iconButtonClasses.disabled}`]: {
        backgroundColor: alpha(theme.palette.common.black, theme.palette.action.selectedOpacity),
        color: theme.palette.grey.A400,
      },

      ...(isActive && {
        backgroundColor: theme.palette[color].dark,
      }),
    }),

    ...(variant === 'tonal' && {
      backgroundColor: theme.palette.lightSurface.main,
      color: theme.palette[color].main,

      '&:hover': {
        backgroundColor: alpha(
          theme.palette[color].main,
          color === 'primaryLight' ? 1 : 0.16 + theme.palette.action.hoverOpacity
        ),
      },

      [`&.${buttonBaseClasses.focusVisible}`]: {
        backgroundColor: alpha(
          theme.palette[color].main,
          color === 'primaryLight' ? 1 : 0.16 + theme.palette.action.focusOpacity
        ),
      },

      [`&.${iconButtonClasses.disabled}`]: {
        backgroundColor: alpha(theme.palette.common.black, theme.palette.action.selectedOpacity),
        color: theme.palette.grey.A400,
      },

      ...(isActive && {
        backgroundColor: alpha(theme.palette[color].main, 0.16 + theme.palette.action.selectedOpacity),

        '&:hover': {
          backgroundColor: alpha(
            theme.palette[color].main,
            0.16 + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
        },
      }),
    }),
  },
}))

export default CIQIconButton
